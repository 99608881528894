.footer-section {
    background: #1F314A;
    height: auto;
    color: #E8EAEC;
  
    .footer-web {
      padding-top: 36px;
      padding-bottom: 36px;
      width: 90%;
  
      .footer-link-column {
        width: 50%;
      }
    }

    a {
      text-decoration: none;
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
  
    .footer-mobile {
      .social-block {
        border-top: 1px solid #616F80;
        border-bottom: 1px solid #616F80;
      }
  
      .faded-text {
        color: #616F80;
      }
    }
  
    .footer-header {
      height: 35px;
    }
  
    .footer-text {
      font-size: 13px;
      line-height: 22px;
      color: #ffffff;
    }
  
    .footer-short-text {
      font-size: 12px !important;
    }
  
    .footer-link-v3 {
      text-decoration: none;
      color: #E8EAEC;
    }
}