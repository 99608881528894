@import '~bootstrap/scss/bootstrap';

  @font-face {
    font-family: 'Circular Std Black';
    src: url('./fonts/CircularStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Circular Std Bold';
    src: url('./fonts/CircularStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Circular Std Book';
    src: url('./fonts/CircularStd-Book.woff') format('woff');
    font-weight: 400;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Circular Std';
    src: url('./fonts/CircularStd-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
  }

  body, html {
    font-family: Circular Std;
  }
.bg-red-blue-gradient {
    background: linear-gradient(90deg, #FFE6F0 0%, #E6F1FE 50.88%, #E4F2FF 100%);
}
  
.carousel-indicators {
    @extend .position-relative, .mb-0, .mt-4;

    height: 8px;

    li {
        background-color: #ffffff !important;
        border-radius: 50%;
        height: 7px !important;
        width: 7px !important;
        border: none !important;
    }

    .active {
      background-color: #4a4a4a !important;
    }
}

  .carousel-item {
      .card {
          box-shadow: none;
      }
  }

  .carousel-inner {
    padding: 15px;
  }

  .text-md-left {
    @media (min-width: 768px) {
      text-align: left!important;
    }
  }

  .w-90 {
    width: 90%;
  }

.general-page-layout {
  background-image: url('./images/eclipse-gradient-web.webp');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  background-color: #fff;
  margin-top: 3rem;
  min-height: calc(100vh - 150px);

  @media (max-width: 576px) {
    background-image: url('./images/eclipse-gradient-mobile.webp');
  }

  .general-page-content {
    color: #616F80 !important;
  }

  .general-page-heading {
    color: #212529 !important;
    line-height: 1.5;
  }
}

.page-content {
  font-family: Circular Std Book;
}

.heavy-tagline {
  font-size: calc(100% + 0.6em);
  line-height: 40px;
  font-weight: bold;

  @media (max-width: 576px) {
    font-size: calc(100% + 0.3em);
    line-height: 28px;
  }
}

.page-banner {
  margin-top: 42px;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 550px;

    @media (max-width: 576px) {
      height: fit-content;
    }
  }
}