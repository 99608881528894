.carousal-home-page {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  
    @media (max-width: 576px) {
      padding: 0;
    }
  
    @media (max-width: 400px) {
      height: 460px;
      display: flex;
      align-items: flex-end;
    }
  
    @media (max-width: 375px) {
      height: 443px;
    }
  
    @media (max-width: 350px) {
      height: 400px;
    }
}

.home-view-carousal {
    width: 25vw;
    margin-left: auto;
    margin-right: 5%;
  
    @media (max-width: 767px) {
      width: 100vw;
      margin: auto;
      padding: 0;
    }
}

.login-image-carousel {
    left: 0;
    @media (max-width: 576px) {
      bottom: 10px;
      left: 120px;
      right: -40% !important;
    }
  
    li {
      border: none !important;
    }
  
    .active {
      background-color: #fff;
    }
}

.form-section {
    border-radius: 16px;
    margin-left: 10%;
    width: 60%;
    background-color: #fff;
    padding: 1.5rem;
  
    @media(max-width: 767px) {
      border-radius: 0;
      margin: auto;
      width: 100%;
    }
}

// .feature-item {
//     width: 70%;
//     display: flex;
//     flex-wrap: wrap;
  
  
//     .feature-photo {
//       width: 80%;
  
//       @media (max-width: 576px) {
//         width: 100%;
//       }
  
//       @media (max-width: 450px) {
//         margin: 0 0 0 15%;
//       }
//     }
  
//     &:nth-of-type(even) {
//       flex-direction: row-reverse;
  
//       .feature-photo {
//         @media (max-width: 450px) {
//           margin: 0 0 0 -15%;
//         }
//       }
//     }
  
//     @media (max-width: 576px) {
//       width: 100%;
//     }
// }
  
// .feature-tag {
//     font-weight: bold;
//     font-size: 12px;
//     line-height: 16px;
//     color: #FF247B;
// }
  
.standard-text {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F314A;
    font-family: Circular Std;
}

// .heavy-font {
//     font-size: calc(100% + 0.2em);
//     font-weight: bold;
//     color: #1F314A;
//     line-height: 26px;
// }

// .jodi-section {
//     background: linear-gradient(180deg, #C3E3FF 0%, #FFC8DE 100%);
// }

// .description-text {
//     font-weight: normal;
//     font-family: Circular Std Book;
//     font-size: 18px;
//     line-height: 28px;
//     color: #1F314A;
// }
  
// .jodi-item {
//     width: 80%;
//     display: flex;
//     flex-wrap: wrap;
  
//     @media (max-width: 576px) {
//       width: 100%;
//     }
  
//     .jodi-subtext {
//       font-size: 12px;
//     }
// }

// .card-title {
//     margin-bottom: 0 !important;
//     font-family: Circular Std;
//     font-size: 1.5rem;
// }

// .card-text {
//     font-family: Circular Std Book;
//     font-weight: 400;
//     font-size: 18px;

//     li {
//         font-size: 0.9rem;
//     }
// }

// .matrimony-content {
//     width: 80%;
//     margin: auto;

//     @media (max-width: 576px) {
//         width: 100%;
//     }
// }

// .form-error {
//   border-color: red !important;
  
//   &:focus {
//     box-shadow: none !important;
//     border-color: red !important;
//   }
// }

.post-item {
  padding: 0 5%;
  justify-content: space-between;

  @media (max-width: 576px) {
    padding: 5% 10%;
  }

  .post-image {
    height: 200px;
    
    img {
      object-fit: cover;
      object-position: center;
      border-radius: 16px;
    }
  }

  .post-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    font-family: sans-serif;
    margin: 10px 0;
    min-height: 44px;
  }

  .post-subtitle {
    font-size: 16px;
    line-height: 18px;
    height: 36px;
    overflow: hidden;
    font-weight: 500;
    font-family: sans-serif;
    margin: 10px 0;

    @media (max-width: 576px) {
      height: 54px;
    }
  }

  .post-button {
    padding: 2px 16px;
    border-radius: 22px;
    background: #0387c6;
    color: #fff;
    width: fit-content;
    margin: 10px 0;
    text-decoration: none;
  }

  .post-btn-icon {
    margin-left: 10px;
  }
}